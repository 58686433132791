import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from './App';
import { useNavigate } from 'react-router-dom';
import { Badge, Button } from 'react-bootstrap';
import useAxios, { useAxiosAuth } from './hooks/useAxios';

const User = ({ userInfo }) => {
    return (
        <div style={{ padding: "1.5rem 0" }}>
            <h3>{userInfo.full_name}</h3>
            <p>Username: {userInfo.username}</p>
            <div>Permissions: {userInfo.permissions.map(permission => <Badge pill bg='info' style={{ marginLeft: "10px", padding: "5px 20px" }}>{permission}</Badge>)}</div>
        </div>
    )
}

const UsageStatistic = ({ statisticInfo }) => {
    return (
        <tr>
            <td>{statisticInfo.httpMethod}</td>
            <td>{statisticInfo.path}</td>
            <td>{statisticInfo.integrationLatency}</td>
            <td>{statisticInfo.responseLatency}</td>
            <td>{statisticInfo.count}</td>
        </tr>
    )
}

const UsageStatisticTable = ({ statistics, type }) => {
    return (
        <table className="table table-striped table-hover">
            <thead>
                <tr>
                    <th>HTTP Method</th>
                    <th>Path</th>
                    <th>Avg. Integration Latency (ms)</th>
                    <th>Avg. Response Latency (ms)</th>
                    <th>Count</th>
                </tr>
            </thead>
            <tbody>
                {
                    statistics.map((stat, i) => (
                        <UsageStatistic key={`${type}-stat-${i}`} statisticInfo={stat} />
                    ))
                }
            </tbody>
        </table>
    )
}

export const AdminPage = () => {
    const authContext = useContext(AuthContext)
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [authServiceUsage, setAuthServiceUsage] = useState([]);
    const [gamesServiceUsage, setGamesServiceUsage] = useState([]);
    const axiosAuth = useAxiosAuth();
    const axios = useAxios();

    const toHome = () => {
        navigate("/");
    }

    useEffect(() => {
        if (authContext.permissions.includes("ADMIN")) {
            // users
            axiosAuth.get("/admin")
                .then(resp => {
                    if (resp.status !== 200) throw Error("Status code returned was not 200")
                    return resp.data
                })
                .then(data => {
                    setUsers(data);
                })
                .catch(e => {
                    console.log(`Error loading admin info: ${e}`)
                })
            
            // games usage
            axios.get("/admin/usage")
                .then(resp => {
                    if (resp.status !== 200) throw Error("Status code returned was not 200")
                    return resp.data
                })
                .then(data => {
                    setGamesServiceUsage(data);
                })
                .catch(e => {
                    console.log(`Error loading admin info: ${e}`)
                })

            // auth usage
            axiosAuth.get("/admin/usage")
                .then(resp => {
                    if (resp.status !== 200) throw Error("Status code returned was not 200")
                    return resp.data
                })
                .then(data => {
                    setAuthServiceUsage(data);
                })
                .catch(e => {
                    console.log(`Error loading admin info: ${e}`)
                })
        }
    }, [axiosAuth, authContext.permissions])

    return (
        <div style={{ padding: "1rem" }}>
            <h1>Admin Dashboard</h1>
            {
                authContext.permissions.includes("ADMIN") ?
                    <div>
                        <h2>Users</h2>
                        {
                            users.map((user, i) => <User key={`user_${i}`} userInfo={user} />)
                        }
                        <div>
                            <h2>Games Service Usage</h2>
                            <UsageStatisticTable type="games" statistics={gamesServiceUsage}/>
                        </div>
                        <div>
                            <h2>Auth Service Usage</h2>
                            <UsageStatisticTable type="auth" statistics={authServiceUsage}/>
                        </div>
                    </div> :
                    <p>You're not an admin. Please login as with admin account to view this page.</p>
            }
            <Button onClick={toHome}>Home</Button>
        </div>
    );
};
