import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import { drawTextBoxes, drawLines, saveCanvasAsPNG, saveCanvasAsPDF, drawPlayers, setupCanvas, handleCleanField } from './canvasUtils';
import { mapPlayersInfoToState, handleDragStart, handleDrop, handleDragOver, handleMouseDown, handleMouseMove, handleMouseUp } from './playersUtils';
import './BoardPage.css';
import useAxios from '../hooks/useAxios';
import { coaches } from './dataBoard';

const BoardPage = () => {
  const axios = useAxios();
  const [playerDataList, setPlayerDataList] = useState([]);

  const canvasRef = useRef(null);
  const [players, setPlayers] = useState([]);

  const [dragging, setDragging] = useState(null);

  const [isDrawingLine, setIsDrawingLine] = useState(false);
  const [isDrawingCurvedLine, setIsDrawingCurvedLine] = useState(false);

  const [lineStart, setLineStart] = useState(null);
  const [lines, setLines] = useState([]);

  const [textBoxes, setTextBoxes] = useState([]);
  const [isTextBoxMode, setIsTextBoxMode] = useState(false);

  const [visibleCategories, setVisibleCategories] = useState({
    forwards: false,
    midfielders: false,
    defenders: false,
    goalkeepers: false,
  });
  const [visibleCoaches, setVisibleCoaches] = useState(true);

  const [deleteMode, setDeleteMode] = useState(false);

  const [draggingTextBox, setDraggingTextBox] = useState(null);

  const toggleCategoryVisibility = (category) => {
    setVisibleCategories(prevState => ({
      ...prevState,
      [category]: !prevState[category]
    }));
  };

  const toggleCoachesVisibility = () => {
    setVisibleCoaches(prevState => !prevState);
  };

  const toggleDeleteMode = () => {
    setDeleteMode(prevMode => !prevMode);
    if (isDrawingLine){
      toggleDrawingMode();
    } else if(isDrawingCurvedLine){
      toggleDrawingCurveMode();
    } else if (isTextBoxMode){
      toggleTextBoxMode();
    }

  };


  const toggleDrawingMode = () => {

    setIsDrawingLine(prevMode => !prevMode)
    const canvasContainer = document.querySelector('.canvas-container');
    if (deleteMode){
      toggleDeleteMode();
    } else if (isTextBoxMode){  
      toggleTextBoxMode();
    } else if (isDrawingCurvedLine){
      toggleDrawingCurveMode();
    }
    if (!isDrawingLine) {
      canvasContainer.style.cursor = 'crosshair';
    } else {
      canvasContainer.style.cursor = 'default';
    }
  };
  const toggleDrawingCurveMode = () => {
    setIsDrawingCurvedLine(prevMode => !prevMode)

    const canvasContainer = document.querySelector('.canvas-container');
    if (deleteMode){
      toggleDeleteMode();
    } else if (isTextBoxMode){  
      toggleTextBoxMode();
    } else if (isDrawingLine){  
      toggleDrawingMode();
    }

    if (!isDrawingCurvedLine) {
      canvasContainer.style.cursor = 'crosshair';
    } else {
      canvasContainer.style.cursor = 'default';
    }
  };
  const toggleTextBoxMode = () => {
    setIsTextBoxMode(prevMode => !prevMode);
    if (deleteMode){
      toggleDeleteMode();
    } else if (isDrawingLine){  
      toggleDrawingMode();
    } else if (isDrawingCurvedLine){
      toggleDrawingCurveMode();
    }
    const canvasContainer = document.querySelector('.canvas-container');
    if (!isTextBoxMode) {
      canvasContainer.style.cursor = 'text';
    } else {
      canvasContainer.style.cursor = 'default';
    }

  };
  useEffect(() => {
    const fetchPlayerData = async () => {
      const response = await axios.get('/player/list');
      setPlayerDataList(response.data);
    };
    fetchPlayerData();
  }, [axios]);

  useEffect(() => {
    if (playerDataList.length > 0) {
      const mappedPlayers = mapPlayersInfoToState(playerDataList);
      setPlayers([...mappedPlayers, ...coaches]);
    }
  }, [playerDataList]);

  useEffect(() => {
    const canvas = canvasRef.current;

    const dropHandler = (e) => {
      handleDrop(e, canvas, players, setPlayers);
    };
    const dragOverHandler = (e) => handleDragOver(e);
    const mouseDownHandler = (e) => handleMouseDown(e, canvas, players, setDragging, deleteMode, 
      setPlayers, setLines, isDrawingLine, setLineStart, canvasRef, lines, isDrawingCurvedLine, isTextBoxMode, 
      setTextBoxes, textBoxes, setDraggingTextBox);
    const mouseMoveHandler = (e) => handleMouseMove(e, canvas, dragging, setPlayers, 
      isDrawingLine, lineStart, canvasRef, setLines, isDrawingCurvedLine, draggingTextBox, setTextBoxes);
    const mouseUpHandler = (e) => handleMouseUp(setDragging, isDrawingLine, 
      canvasRef, setLines, setLineStart, lineStart, e, isDrawingCurvedLine, setDraggingTextBox);

    canvas.addEventListener('dragover', dragOverHandler);
    canvas.addEventListener('drop', dropHandler);
    canvas.addEventListener('mousedown', mouseDownHandler);
    canvas.addEventListener('mousemove', mouseMoveHandler);
    canvas.addEventListener('mouseup', mouseUpHandler);

    return () => {
      canvas.removeEventListener('dragover', dragOverHandler);
      canvas.removeEventListener('drop', dropHandler);
      canvas.removeEventListener('mousedown', mouseDownHandler);
      canvas.removeEventListener('mousemove', mouseMoveHandler);
      canvas.removeEventListener('mouseup', mouseUpHandler);
    };
  }, [players, dragging, deleteMode, lines, isDrawingLine, lineStart,
     isDrawingCurvedLine, textBoxes, isTextBoxMode, draggingTextBox]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    setupCanvas(canvas, context, './free-soccer-field-vector.jpg', () => {
        drawPlayers(context, players);
        drawLines(context, lines);
        drawTextBoxes(context, textBoxes); // Draw the text boxes

    });
  },[players, lines, textBoxes]);




  return (
      <div className="board-page-container">
        <div className="buttons-container">
          <button className="clean-button" onClick={() => handleCleanField(players, setPlayers, setLines, setTextBoxes)}>CLEAN</button>
          <button className="save-pdf-button" onClick={() => saveCanvasAsPDF(canvasRef.current.getContext('2d'))}>SAVE PDF</button>
          <button className="save-png-button" onClick={() => saveCanvasAsPNG(canvasRef.current)}>SAVE PNG</button>
        </div>
        <div className="canvas-container">
          <img src="./free-soccer-field-vector.jpg" alt="Background Image" className="background-image" />
          <canvas ref={canvasRef} width="800" height="600" style={{ border: '1px solid #ccc' }} className="overlay-canvas">
            Your browser does not support the HTML5 canvas tag.
          </canvas>
          <div className="players-container">
            <div className="category-wrapper">
              <h1 className="players-title">PLAYERS</h1>
              {['forwards', 'midfielders', 'defenders', 'goalkeepers'].map(category => (
                <div key={category} className="category">
                  <div className="category-title" onClick={() => toggleCategoryVisibility(category)}>
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                  </div>
                  {visibleCategories[category] && (
                    <div className="category-players">
                      {players.filter(player => player.category === category && player.x === null && player.y === null).map((player, index) => (
                        <div
                          key={index}
                          className="player"
                          draggable
                          onDragStart={(e) => handleDragStart(e, player, setPlayers)}
                        >
                          {player.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="coaches-button-container">
              <button className="coaches-button" onClick={toggleCoachesVisibility}>COACHES</button>
              {visibleCoaches && (
                <div className="coaches-list">
                  {players.filter(player => player.category === 'coaches' && player.x === null && player.y === null).map((coach, index) => (
                    <div
                      key={index}
                      className="player coach"
                      draggable
                      onDragStart={(e) => handleDragStart(e, coach, setPlayers)}
                    >
                      {coach.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bottom-bar">
          <div className="bar-title">Field Actions</div>
          <i
            className={`fas fa-trash-alt delete-icon ${deleteMode ? 'active' : ''}`}
            onClick={toggleDeleteMode}
            title="Toggle Delete Mode"
          />
          <i
            className={`fas fa-arrow-right draw-icon ${isDrawingLine ? 'active' : ''}`}
            onClick={toggleDrawingMode}
            title="Toggle Drawing Mode"
          />     
          <i
            className={`fas fa-undo drawCurved-icon ${isDrawingCurvedLine ? 'active' : ''}`}
            onClick={toggleDrawingCurveMode}
            title="Toggle Curved-Drawing Mode"
          />
          <i
            className={`fa-solid fa-font textBox-icon ${isTextBoxMode ? 'active' : ''}`}
            onClick={toggleTextBoxMode}
            title="Toggle Text Box Mode"
          />

        </div>
      </div>
  );
};

export default BoardPage;
