import jsPDF from 'jspdf';
export const saveCanvasAsPNG = (canvas) => {
  if (!canvas) {
    console.error('Canvas is not defined');
    return;
  }
  // Convert canvas to image data URL
  const canvasData = canvas.toDataURL('image/png');

  // Create a temporary link element
  const link = document.createElement('a');
  link.href = canvasData;
  link.download = 'MSOESoccerBoard.png';

  // Trigger the download by programmatically clicking the link
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export const drawLines = (ctx, lines) => {
  ctx.strokeStyle = 'black';
  ctx.lineWidth = 2;

  lines.forEach(line => {
    ctx.beginPath();

    if (line.path) {
      // Draw curved line
      ctx.moveTo(line.path[0].x, line.path[0].y);
      for (let i = 1; i < line.path.length; i++) {
        ctx.lineTo(line.path[i].x, line.path[i].y);
      }
      ctx.stroke();

      // Draw arrowhead for curved lines
      const end = line.path[line.path.length - 1];
      const control = line.path[Math.floor(line.path.length / 2)];
      const start = line.path[0];
      drawArrowhead(ctx, end, control);
    } else if (line.start && line.end) {
      // Draw straight line
      ctx.moveTo(line.start.x, line.start.y);
      ctx.lineTo(line.end.x, line.end.y);
      ctx.stroke();

      // Draw arrowhead for straight lines
      drawArrowhead(ctx, line.end, line.start);
    }
  });
};

const drawArrowhead = (ctx, end, start) => {
  const headLength = 10; // Length of the arrowhead

  // Calculate the direction vector
  const dx = end.x - start.x;
  const dy = end.y - start.y;
  const length = Math.sqrt(dx * dx + dy * dy);

  // Normalize the direction vector
  const unitX = dx / length;
  const unitY = dy / length;

  // Calculate the points for the arrowhead
  const x1 = end.x - headLength * (unitX * Math.cos(Math.PI / 6) - unitY * Math.sin(Math.PI / 6));
  const y1 = end.y - headLength * (unitX * Math.sin(Math.PI / 6) + unitY * Math.cos(Math.PI / 6));
  const x2 = end.x - headLength * (unitX * Math.cos(-Math.PI / 6) - unitY * Math.sin(-Math.PI / 6));
  const y2 = end.y - headLength * (unitX * Math.sin(-Math.PI / 6) + unitY * Math.cos(-Math.PI / 6));

  ctx.beginPath();
  ctx.moveTo(end.x, end.y);
  ctx.lineTo(x1, y1);
  ctx.moveTo(end.x, end.y);
  ctx.lineTo(x2, y2);
  ctx.stroke();
};
export const saveCanvasAsPDF = (context) => {
  if (!context) {
    console.error('Canvas is not defined');
    return;
  }
  // Get canvas dimensions
  const width = context.canvas.width;
  const height = context.canvas.height;

  // Create a new jsPDF instance with dimensions matching the canvas
  const pdf = new jsPDF({
    orientation: 'landscape', // Use portrait orientation
    unit: 'px', // Use pixels as unit
    format: [width, height] // Set PDF size to match canvas size
  });

  // Convert canvas to image data URL
  const canvasData = context.canvas.toDataURL('image/png');

  // Add image data URL to PDF
  pdf.addImage(canvasData, 'PNG', 0, 0, width, height);

  // Save the PDF
  pdf.save('MSOESoccerBoard.pdf');
};
export const drawPlayers = (ctx, players) => {
  if (!players) return; // Return early if players is undefined

  players.forEach(player => {
    if (player.x !== null && player.y !== null) {
      const { x, y, width, height, name, category } = player;
      const radius = 10; // Radius for rounded corners

      // Draw the shadow
      ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
      ctx.shadowBlur = 5;
      ctx.shadowOffsetX = 3;
      ctx.shadowOffsetY = 3;

      // Draw the rounded rectangle background
      ctx.beginPath();
      ctx.moveTo(x + radius, y);
      ctx.lineTo(x + width - radius, y);
      ctx.arcTo(x + width, y, x + width, y + radius, radius);
      ctx.lineTo(x + width, y + height - radius);
      ctx.arcTo(x + width, y + height, x + width - radius, y + height, radius);
      ctx.lineTo(x + radius, y + height);
      ctx.arcTo(x, y + height, x, y + height - radius, radius);
      ctx.lineTo(x, y + radius);
      ctx.arcTo(x, y, x + radius, y, radius);
      ctx.closePath();

      // Gradient background
      const gradient = ctx.createLinearGradient(x, y, x, y + height);
      if (category === 'coaches') {
        gradient.addColorStop(0, 'red');
        gradient.addColorStop(1, 'darkred');
      } else {
        gradient.addColorStop(0, 'lightgray');
        gradient.addColorStop(1, 'gray');
      }
      ctx.fillStyle = gradient;
      ctx.fill();

      // Draw the border
      ctx.strokeStyle = 'black';
      ctx.lineWidth = 1;
      ctx.stroke();

      // Reset shadow settings for text drawing
      ctx.shadowColor = 'transparent';

      // Determine the appropriate font size
      let fontSize = 10; // Start with a default font size
      ctx.font = `${fontSize}px Arial`;

      // Calculate the width of the text
      let textWidth = ctx.measureText(name).width;

      // Adjust the font size to fit within the player box width
      while (textWidth > width && fontSize > 1) {
        fontSize -= 1;
        ctx.font = `${fontSize}px Arial`;
        textWidth = ctx.measureText(name).width;
      }

      // Draw the player's name
      ctx.fillStyle = 'black';
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'center';
      const textX = x + width / 2;
      const textY = y + height / 2;
      ctx.fillText(name, textX, textY);
    }
  });
};
export const drawTextBoxes = (ctx, textBoxes, maxCharsPerLine = 20) => {
  textBoxes.forEach(box => {
    ctx.font = '10px Arial'; // Increase font size for clarity
    const padding = 10; // Increased padding for better spacing

    let words = box.text.split(' ');
    let lines = [];
    let currentLine = '';

    words.forEach(word => {
      while (word.length > maxCharsPerLine) {
        lines.push(word.slice(0, maxCharsPerLine));
        word = word.slice(maxCharsPerLine);
      }
      
      if ((currentLine + word).length > maxCharsPerLine) {
        lines.push(currentLine);
        currentLine = word;
      } else {
        if (currentLine.length > 0) {
          currentLine += ' ';
        }
        currentLine += word;
      }
    });

    if (currentLine.length > 0) {
      lines.push(currentLine);
    }

    const textWidth = Math.max(...lines.map(line => ctx.measureText(line).width));
    const textHeight = 10; // Height for the increased font size
    const boxWidth = textWidth + padding * 2;
    const boxHeight = lines.length * textHeight + padding * 2;

    ctx.fillStyle = 'white';
    ctx.fillRect(box.x, box.y, boxWidth, boxHeight);

    ctx.strokeStyle = 'black';
    ctx.strokeRect(box.x, box.y, boxWidth, boxHeight);

    ctx.fillStyle = 'black';
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'center';
    lines.forEach((line, index) => {
      ctx.fillText(
        line,
        box.x + boxWidth / 2,
        box.y + padding + index * textHeight + textHeight / 2
      );
    });
  });
};




export const handleCleanField = (players, setPlayers, setLines, setTextBoxes) => {
  const cleanedPlayers = players.map(player => ({
    ...player,
    x: null,
    y: null,
  }));
  setPlayers(cleanedPlayers);
  setLines([]); // Reset the lines
  setTextBoxes([]); // Reset the text boxes
};
  export const setupCanvas = (canvas, context, imgSrc, callback) => {

    const img = new Image();
    const devicePixelRatio = window.devicePixelRatio || 1;

    // Set canvas size in CSS pixels
    canvas.style.width = '800px'; // or any width you need
    canvas.style.height = '600px'; // or any height you need

    // Scale canvas size for high-DPI displays
    canvas.width = 800 * devicePixelRatio;
    canvas.height = 600 * devicePixelRatio;
    context.scale(devicePixelRatio, devicePixelRatio);

    // Scale the context to match the device pixel ratio
    img.onload = () => {

      context.drawImage(img, 0, 0, context.canvas.width / devicePixelRatio, context.canvas.height / devicePixelRatio); // Draw image on canvas
      
      callback();
    };
    img.src = imgSrc; // Adjust path if necessary
  };
  
  
  export const isPointNearLine = (px, py, x1, y1, x2, y2, threshold = 5) => {
    const A = px - x1;
    const B = py - y1;
    const C = x2 - x1;
    const D = y2 - y1;
  
    const dot = A * C + B * D;
    const lenSq = C * C + D * D;
    const param = lenSq !== 0 ? dot / lenSq : -1;
  
    let xx, yy;
  
    if (param < 0) {
      xx = x1;
      yy = y1;
    } else if (param > 1) {
      xx = x2;
      yy = y2;
    } else {
      xx = x1 + param * C;
      yy = y1 + param * D;
    }
  
    const dx = px - xx;
    const dy = py - yy;
  
    return (dx * dx + dy * dy) <= threshold * threshold;
  };
  
  