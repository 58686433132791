import React, { useState, useEffect, useRef, useLayoutEffect, useContext } from 'react';
import { AuthContext } from './App';
import useAxios from './hooks/useAxios';

const isEmpty = (x) => {
    return x === '' || x === undefined || x === null;
}

export const Game = ({ teamNumberIdMappings, gameInfo, locked }) => {
    const authContext = useContext(AuthContext);
    const axios = useAxios();

    const [currentGameInfo, setCurrentGameInfo] = useState({
        team1Number: gameInfo.team1_number,
        team2Number: gameInfo.team2_number,
        team1Score: gameInfo.team1_score,
        team2Score: gameInfo.team2_score
    });

    const setTeam1Number = (v) => {
        const currentGameInfoCopy = { ...currentGameInfo };
        currentGameInfoCopy.team1Number = v;
        setCurrentGameInfo(currentGameInfoCopy);
        updateGame(currentGameInfoCopy);
    }

    const setTeam2Number = (v) => {
        const currentGameInfoCopy = { ...currentGameInfo };
        currentGameInfoCopy.team2Number = v;
        setCurrentGameInfo(currentGameInfoCopy);
        updateGame(currentGameInfoCopy);
    }

    const setTeam1Score = (v) => {
        const currentGameInfoCopy = { ...currentGameInfo };
        currentGameInfoCopy.team1Score = v;
        setCurrentGameInfo(currentGameInfoCopy);
        updateGame(currentGameInfoCopy);
    }

    const resetTeam1Score = () => {
        if (isEmpty(currentGameInfo.team1Score)) {
            setTeam1Score(0);
        }
    }

    const setTeam2Score = (v) => {
        const currentGameInfoCopy = { ...currentGameInfo };
        currentGameInfoCopy.team2Score = v;
        setCurrentGameInfo(currentGameInfoCopy);
        updateGame(currentGameInfoCopy);
    }

    const resetTeam2Score = () => {
        if (isEmpty(currentGameInfo.team2Score)) {
            setTeam2Score(0);
        }
    }

    // every time you change one of these, sends out a database update
    const updateGame = (updateGameInfo) => {
        if (isEmpty(updateGameInfo.team1Score) || isEmpty(updateGameInfo.team2Score)) return;
        const updateGameBody = {
            game_id: gameInfo.game_id,
            round_number: gameInfo.round_number,
            team1_id: teamNumberIdMappings[updateGameInfo.team1Number],
            team2_id: teamNumberIdMappings[updateGameInfo.team2Number],
            team1_score: parseInt(updateGameInfo.team1Score),
            team2_score: parseInt(updateGameInfo.team2Score)
        };

        axios.patch('/game', updateGameBody);
    };

    return (
        <tr>
            <td style={{ textAlign: "right" }}>
                {
                    !authContext.permissions.includes("UPDATE") ?
                        currentGameInfo.team1Number :
                        <select value={currentGameInfo.team1Number} onChange={(e) => setTeam1Number(e.target.value)}>
                            {Object.keys(teamNumberIdMappings).map((key) => (
                                <option key={key} value={key}>
                                    {key}
                                </option>
                            ))}
                        </select>
                }
            </td>
            <td className='center-text'>vs</td>
            <td>
                {
                    !authContext.permissions.includes("UPDATE") ?
                        currentGameInfo.team2Number :
                        <select value={currentGameInfo.team2Number} onChange={(e) => setTeam2Number(e.target.value)}>
                            {Object.keys(teamNumberIdMappings).map((key) => (
                                <option key={key} value={key}>
                                    {key}
                                </option>
                            ))}
                        </select>
                }
            </td>
            <td style={{ textAlign: "right" }}>
                {
                    !authContext.permissions.includes("UPDATE") ?
                        currentGameInfo.team1Score :
                        <input className="team-1-score" value={currentGameInfo.team1Score} type="number" onBlur={resetTeam1Score} onChange={(e) => setTeam1Score(e.target.value)} />
                }
            </td>
            <td className='center-text'>-</td>
            <td>
                {
                    !authContext.permissions.includes("UPDATE") ?
                        currentGameInfo.team2Score :
                        <input value={currentGameInfo.team2Score} type="number" onBlur={resetTeam2Score} onChange={(e) => setTeam2Score(e.target.value)} />
                }
            </td>
        </tr>
    );
}