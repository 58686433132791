import React, { createContext, useContext, useState } from 'react';
import NavBar from './NavBar';
import { Route, Routes } from 'react-router-dom';
import { SignIn } from './SignIn';
import { AdminPage } from './AdminPage';

export const AuthContext = createContext({});

const App = () => {
  const [userInfo, setUserInfo] = useState({});
  const [permissions, setPermissions] = useState(["READ"]);

  return (
    <AuthContext.Provider value={{ userInfo, setUserInfo, permissions, setPermissions }}>
        <Routes>
          <Route path={"/sign-in"} element={<SignIn />}></Route>
          <Route path={"/admin"} element={<AdminPage/>}></Route>
          <Route path={"/"} element={<NavBar />}></Route>
        </Routes>
    </AuthContext.Provider>
  );
}


export default App;