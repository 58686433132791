import React, { useState, useEffect, useContext } from 'react';
import { GameRound } from './GameRound';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/Modal';
import { AuthContext } from './App';
import useAxios from './hooks/useAxios';

const getRoundGroups = (games) => {
    const roundNumbers = Array.from(new Set(games.map(game => game.round_number)));
    const roundGroups = {};
    roundNumbers.forEach(roundNumber => {
        roundGroups[roundNumber] = [];
    })
    games.forEach(game => {
        roundGroups[game.round_number].push(game);
    })
    return roundGroups;
}

const GameCard = ({ practiceName, practiceInfo, locked }) => {
    const authContext = useContext(AuthContext);
    const axios = useAxios();

    // initial team number id mappings
    const teamNumberIdMappingsCopy = {};
    practiceInfo.teams.forEach(teamInfo => {
        const round_number = teamInfo.round_number;
        if (!teamNumberIdMappingsCopy.hasOwnProperty(round_number)) {
            teamNumberIdMappingsCopy[round_number] = {};
        }
        teamNumberIdMappingsCopy[round_number][teamInfo.team_number] = teamInfo.team_id;
    })

    // initial rounds
    const roundsCopy = Object.entries(getRoundGroups(practiceInfo.games)).toSorted((a, b) => parseInt(a[0]) - parseInt(b[0]));
    const [rounds, setRounds] = useState(roundsCopy);
    const [teamNumberIdMappings, setTeamNumberIdMappings] = useState(teamNumberIdMappingsCopy);

    const handleAddRound = () => {
        axios.patch('/round', {
            practice_id : practiceName,
            round_number : rounds.length + 1 // +1 means next round
        })
        .then(resp => {
            if (resp.status !== 200) throw new Error("Response didn't return with status code 200")
            return resp.data // json()
        })
        .then(data => {
            // rounds update
            const roundsCopy = [...rounds];
            const roundDefaultGames = [];
            data.games.forEach(game => {
                game["team1_score"] = 0;
                game["team2_score"] = 0;
                game["round_number"] = data.round_number;
                roundDefaultGames.push(game);
            })
            roundsCopy.push(['' + data.round_number, roundDefaultGames]);

            // team number id mappings update
            const roundTeamNumberIdMappings = {};
            data.teams.forEach(team => {
                roundTeamNumberIdMappings[team.team_number] = team.team_id;
            })
            const teamNumberIdMappingsCopy = {...teamNumberIdMappings};
            teamNumberIdMappingsCopy[data.round_number] = roundTeamNumberIdMappings;
            
            // update state
            setRounds(roundsCopy);
            setTeamNumberIdMappings(teamNumberIdMappingsCopy);
        })
    }

    return (
        <>
            <table className="table table-striped table-hover game-card">
                <tbody>
                    {
                        rounds.map(roundData => <GameRound key={roundData[0]} practiceName={practiceName} roundNumber={roundData[0]} roundInfo={roundData[1]} teamNumberIdMappings={teamNumberIdMappings[roundData[0]]} locked={locked} />)
                    }
                </tbody>
            </table>
            {
                authContext.permissions.includes("UPDATE") &&
                <div>
                    <Button className='wide-button' onClick={handleAddRound}>Add Round</Button>
                </div>
            }
        </>
    )
}

const PracticeInfo = ({ practiceName, locked }) => {
    const authContext = useContext(AuthContext);
    const axios = useAxios();
    const [isSelected, setIsSelected] = useState(false);
    const [practiceInfo, setPracticeInfo] = useState(null);

    // place for potential improvement, because every time you reopen, you trigger this
    useEffect(() => {
        if (isSelected) {
                axios.get(`/practice?practice_id=${practiceName}`)
                .then(response => {
                    if (response.status !== 200) throw new Error("Response didn't return with status code 200")
                    return response.data // json()
                })
                .then(practiceInfoResp => { 
                    // console.log(practiceInfoResp)
                    setPracticeInfo(practiceInfoResp); 
                })
                .catch(err => {
                    console.log(`Error loading practice information`)
                });
        } else {
            setPracticeInfo(null)
        }
    }, [isSelected]);

    return (
        <>
            <div className='practice-card'>
                <h3 onClick={() => setIsSelected(!isSelected)}>{practiceName}</h3>
                {
                    practiceInfo !== null ?
                        <GameCard practiceName={practiceName} practiceInfo={practiceInfo} locked={locked} />
                        : null
                }
            </div>

        </>
    );
}

const PreviousPracticesPage = ({ locked }) => {
    const authContext = useContext(AuthContext);
    const [practicesList, setPracticesList] = useState([]);
    const axios = useAxios();

    useEffect(() => {
        axios.get('/practice/list')
            .then(response => {
                if (response.status !== 200) throw new Error("Response didn't return with status code 200")
                return response.data;
            })
            .then(practicesListResp => {
                const namePartsRegex = /(\d{4}-\d{2}-\d{2})-(.+)/;
                const sortedPracticesListResp = practicesListResp.toSorted((a, b) => {
                    const namePartsA = namePartsRegex.exec(a);
                    const namePartsB = namePartsRegex.exec(b);
                    if (namePartsA[1] === namePartsB[1]) { // dates match
                        return namePartsA[2].localeCompare(namePartsB[2]);
                    } else {
                        return -namePartsA[1].localeCompare(namePartsB[1]);
                    }
                });
                setPracticesList(sortedPracticesListResp);
            })
            .catch(err => {
                console.log(`Error loading practices`)
                throw err
            });
    }, []);

    return (
        <>
            <div>
                {
                    practicesList.map(practiceName =>
                        <PracticeInfo key={practiceName} practiceName={practiceName} locked={locked} />
                    )
                }
            </div>
        </>
    );
}

export default PreviousPracticesPage;
