import React, { useContext, useEffect, useState } from 'react'; // import react
import { useMediaQuery } from 'react-responsive';
import NewPracticePage from './NewPracticePage';
import StatsPage from './StatsPage';
import PreviousPracticesPage from './PreviousPracticesPage';
import { Modal, Button, ModalTitle } from 'react-bootstrap';
import { FaLock, FaLockOpen } from "react-icons/fa";
import { AuthContext } from './App';
import { Link, useNavigate } from 'react-router-dom';
import { axiosAuthService, useRefresh } from './hooks/useAxios';
import { IoPerson } from "react-icons/io5";
import Cookies from "js-cookie";
import BoardPage from './BoardPage/BoardPage';

const NavBarButton = ({tabInfo, currTab}) => {
    if (tabInfo.key === "home") {
        return <div className="nav-bar-home" onClick={tabInfo.setTab}><img src={tabInfo.img} alt={tabInfo.name}/></div>
    } else {
        return <div className={tabInfo.key === currTab ? "nav-bar-btn-selected" : "nav-bar-btn"} key={tabInfo.name} onClick={tabInfo.setTab}><p>{tabInfo.name}</p></div>
    }
};

// number, name, position, onCheckBoxChange are the props (like parameters)
const NavBar = ({ name, position }) => {
    const isTabletOrMobile = useMediaQuery({ query : '(max-width: 1224px)'})
    const [currTab, setCurrTab] = useState("newPractice");
    const [showPasswordEntry, setShowPasswordEntry] = useState(false);
    const [password, setPassword] = useState("");
    const authContext = useContext(AuthContext);
    const [locked, setLocked] = useState(!('access_token' in authContext.userInfo));
    const navigate = useNavigate();
    const refresh = useRefresh();

    const [showUserInfo, setShowUserInfo] = useState(false);

    const TABS = {
        previousPractices : <PreviousPracticesPage locked={locked}/>,
        newPractice : <NewPracticePage locked={locked}/>,
        stats: <StatsPage/>,
        board: <BoardPage/>
    };

    const TAB_INFO = [
        {
            name : "Home",
            key : "home",
            img : "/raider-logo.png",
            hideOnMobile : true,
            setTab : () => setCurrTab("previousPractices")
        },
        {
            name: "Practice",
            key: "previousPractices",
            hideOnMobile : false,
            setTab: () => setCurrTab("previousPractices"),
        },
        {
            name: "New",
            key: "newPractice",
            hideOnMobile : false,
            setTab: () => setCurrTab("newPractice"),
        },
        {
            name: "Stats",
            key: "stats",
            hideOnMobile : false,
            setTab: () => setCurrTab("stats"),
        },
        {
            name: "Board",
            key: "board",
            hideOnMobile : false,
            setTab: () => setCurrTab("board"),
        }
    ];

    const login = () => {
        navigate("/sign-in");
    }

    const logout = () => {
        authContext.setUserInfo({});
        authContext.setPermissions([]);
        axiosAuthService.post("/logout")
        console.log("logging out")
        setLocked(true);
    }

    useEffect(() => {
        refresh()
    }, []);

    useEffect(() => {
        setLocked(!('access_token' in authContext.userInfo))
    }, [authContext.userInfo])

    useEffect(() => {
        console.log(authContext.permissions);
    }, [authContext.permissions])

    const handleClose = () => {
        setShowUserInfo(false);
    }

    const handleOpen = () => {
        setShowUserInfo(true)
    }

    return (
        <>
            <div>
                {/* <div className='password-container'>
                        <Button className='password' onClick={() => setShowPasswordEntry(true)}>Enter Password</Button>
                    </div> */}
        

                <div className='secondary-div' style={{height : "8px"}}/>
                <div className='primary-div nav-bar' style={{ display: 'flex', justifyContent: 'space-between'}}>
                    {
                        TAB_INFO.filter(tabInfo => !isTabletOrMobile || !tabInfo.hideOnMobile).map(tabInfo => <NavBarButton key={tabInfo.key} tabInfo={tabInfo} currTab={currTab}/>)
                    }
                    <div className="nav-bar-btn-small" key="password" onClick={handleOpen}>{
                        <IoPerson/>
                    }</div>
                </div>
                {TABS[currTab]}
                
            </div>
            <Modal show={showUserInfo}>
            <Modal.Header>
                <Modal.Title>User Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    {
                        locked ? <>
                                <Modal.Title>Guest</Modal.Title>
                                <p>Login for further permissions.</p>
                            </> : <>
                                <Modal.Title>{authContext.userInfo.full_name}</Modal.Title>
                                <p>Username: {authContext.userInfo.username}</p>
                                {
                                    authContext.permissions.includes("ADMIN") && <Link to="/admin">Admin Dashboard</Link>
                                }
                            </>
                    }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    Done
                </Button>
                <Button variant="primary" onClick={locked ? login : logout}>
                    {(locked ? "Login" : "Logout")}
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    )
};

export default NavBar;