import React, { useContext, useEffect, useState } from 'react';
import GeneratePage from './GeneratePage';
import ProgressBar from './ProgressBar';
import PlayerComponent from './PlayerComponent';
import TrackResults from "./TrackResults";
import { AuthContext } from './App';
import useAxios from './hooks/useAxios';

const NewPracticePage = ({ locked }) => {
    const authContext = useContext(AuthContext);
    const axios = useAxios();
    const [progress, setProgress] = useState(0);

    const checkpoints = [
        { id: 0, label: "Select Players" },
        { id: 1, label: "Create Teams" },
        // { id: 2, label: "Track Results" }
    ]

    const [data, setData] = useState([]);

    useEffect(() => {
        let tmpIsPlayerSelected = JSON.parse(localStorage.getItem("isPlayerSelected"));
        if (tmpIsPlayerSelected === null) {
            tmpIsPlayerSelected = {};
        }

        axios.get('/player/list')
        .then(resp => {
            if (resp.status !== 200) throw new Error("Response didn't return with status code 200")
            return resp.data;
        })
        .then(respData => {
            setData(respData.map(player => {
                return {
                    ...player,
                    isSelected : (player.name in tmpIsPlayerSelected ? tmpIsPlayerSelected[player.name] : true)
                }
            }))
        })
        .catch((e) => {
            console.log(`Error Reading Players: ${e}`)
        });
    }, [])

    useEffect(() => {
        let tmpIsPlayerSelected = JSON.parse(localStorage.getItem("isPlayerSelected"));
        if (tmpIsPlayerSelected === null) {
            tmpIsPlayerSelected = {};
        }
        data.forEach(player => {
            tmpIsPlayerSelected[player.name] = player.isSelected;
        })
        localStorage.setItem("isPlayerSelected", JSON.stringify(tmpIsPlayerSelected));
    }, [data])

    return (
        <div className='App'>
            
            <ProgressBar checkpoints={checkpoints} progress={progress} setProgress={setProgress}/>
            {
                progress === 0 ? 
                    <PlayerComponent data={ data } setData = { setData } /> :
                    // progress === 1 ?
                        <GeneratePage data={ data } locked={locked}/> // :
                        // <TrackResults />
            }
        </div>
    );
}

export default NewPracticePage;
        
        