import React from 'react'; // import react
import './App.css'; // import css styling
// import { useMediaQuery } from 'react-responsive';
import StyledCheckbox from './StyledCheckBox';

// number, name, position, onCheckBoxChange are the props (like parameters)
const PlayerComponent = ({ data, setData }) => {

  // creates variable data and function setData
  // const [selectedPlayers, setSelectedPlayers] = useState([]);
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const handleCheckboxChange = (toggleIndex) => {
    // Toggle the isSelected status
    const updatedData = data.map((player, index) =>
      toggleIndex === index ? { ...player, isSelected: !player.isSelected } : player
    );

    // Update the state with the modified data
    setData(updatedData);

    // Update the list of selected players
    // const selected = updatedData.filter((player) => player.isSelected);
    // setSelectedPlayers(selected);
  };

  return (
    <>
      <h2>Team Sheet</h2>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Name</th>
            <th>Position</th>
            <th>In?</th>
          </tr>
        </thead>
        <tbody>
          {data.map((player, index) => (
            <tr className={player.isSelected ? 'player-in-' + (index % 2)  : 'player-out-' + (index % 2)} key={player.name + player.id} onClick={() => handleCheckboxChange(index)}>
              <td>{player.name}</td>
              <td>{player.position}</td>
              <td>
                <StyledCheckbox checked={player.isSelected} onChange={() => handleCheckboxChange(index)}/>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>

  );
};

export default PlayerComponent;
