import React, { useEffect, useState } from 'react';
import PlayersStats from './PlayersStats';
// import { useMediaQuery } from 'react-responsive';
// import { AuthContext } from './App';
import useAxios from './hooks/useAxios';
import globals from './globals';
import Form from 'react-bootstrap/Form';

const StatsPage = () => {
    // const authContext = useContext(AuthContext);
    const axios = useAxios();
    // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const [playersStatsData, setPlayerStats] = useState([]);
    const [possibleGameTypes, setPossibleGameTypes] = useState([]);

    const [gameTypes, setGameTypes] = useState([]);
    const [sortColumn, setSortColumn] = useState("Points");
    const [sortAscending, setSortAscending] = useState(false);

    const [selectedSeason, setSelectedSeason] = useState(globals.currentSeason); // Add state for selected season
    useEffect(() => {
            console.log(globals.currentSeason);
            axios.post('/game-types/stats', { game_types : gameTypes, season : selectedSeason })
            .then(response => {
                if (response.status !== 200) throw new Error("Response didn't return with status code 200")
                return response.data // json()
            })
            .then(playerStats => { 
                setPlayerStats(playerStats); 
            })
            .catch(err => {
                console.log(`Error loading stats: ${err}`)
            });
    }, [axios, gameTypes, selectedSeason]);

    useEffect(() => { 
        axios.post('/game-types', { season : selectedSeason} )
        .then(response => {
            if (response.status !== 200) throw new Error("Response didn't return with status code 200")
            return response.data // json()
        })
        .then(possGameTypes => { 
            setPossibleGameTypes(possGameTypes); 
        })
        .catch(err => {
            console.log(`Error loading stats: ${err}`)
        });
    }, [axios, selectedSeason]);

    // const sortTable = (column) => {
    //     const [PlayersStats, setTableData] = useState([])
    //     const sortedTable = [...tableData];

    //     sortedTable.sort((a, b) => a[column] - b[column]);

    //     setTableData(sortedTable);
    //   };

    const sortableColumns = ["W", "T", "L", "Played", "Points", "GF", "GA", "GD", "GF/Game", "GA/Game", "GD/Game", "Win%"];
    const sortableColumnMap = {
        "W" : "wins",
        "T" : "draws", 
        "L" : "losses", 
        "Played" : "games", 
        "Points" : "points", 
        "GF" : "gf", 
        "GA" : "ga", 
        "GD" : "gd", 
        "GF/Game" : "gf_game", 
        "GA/Game" : "ga_game", 
        "GD/Game" : "gd_game", 
        "Win%" : "win_percent"
    }

    const changeSorting = (colName) =>  {
        if (sortColumn === colName) {
            setSortAscending(!sortAscending);
        } else {
            setSortColumn(colName);
        }
    }

  const toggleButton = (value) => {
    if (value === 'ALL') {
        setGameTypes([]);
        return;
    }
    setGameTypes([value]); // a tough design decision? -> we can switch if we want
    // if (gameTypes.includes(value)) {
    //   setGameTypes(gameTypes.filter((item) => item !== value));
    // } else {
    //     setGameTypes([...gameTypes, value]);
    // }
  };

    return (
        <div className='App'>
            <h1>Stats Table</h1>
            <div className="season-select-wrapper">
                <Form style={{ margin: "1rem" }}>
                    <Form.Select value={selectedSeason} onChange={(e) => setSelectedSeason(e.target.value)} className="season-select custom-select">
                        <option value="" disabled>Select Season</option>
                        <option value="Fall 2024">
                            <i className="fas fa-users"></i> Fall 2024
                        </option>
                        <option value="2023">
                            <i className="fas fa-futbol"></i> Spring 2024
                        </option>
                    </Form.Select>
                </Form>
            </div>
            <div className="d-flex flex-wrap" role="group" aria-label="Toggle button group">
                <button
                    type="button"
                    className={`btn m-2 ${gameTypes.length === 0 ? 'btn-success' : 'btn-danger'}`}
                    onClick={() => toggleButton('ALL')}
                > 
                    ALL
                </button>
                
                {
                    possibleGameTypes.map((gameType, index) => (
                        <button
                            type="button"
                            key={`${gameType}_${index}`}
                            className={`btn m-2 ${gameTypes.includes(gameType) ? 'btn-success' : 'btn-danger'}`}
                            onClick={() => toggleButton(gameType)}
                        > 
                            {gameType}
                        </button>
                    ))
                }
            </div>
            <div className="stats table-container">
                <table className="table">
                    <thead className='fix-header'>
                        <tr>
                            <th className='fix-column-odd'>Name</th>
                            <th>Position</th>
                            {
                                sortableColumns.map(colName => {
                                    const selectedClass = sortColumn === colName ? "sorted" : "";
                                    const sortingAddition = (colName === sortColumn) ? (sortAscending ? '▲' : '▼') : '';
                                    return (<th className={`sort-column + ${selectedClass}`} key={colName} onClick={() => changeSorting(colName)}>{colName + sortingAddition}</th> );
                                })
                            }

                            {/* <th>W</th>
                            <th>T</th>
                            <th>L</th>
                            <th>Played</th>
                            <th>Points</th>
                            <th>GF</th>
                            <th>GA</th>
                            <th>GD</th>
                            <th>GF/Game</th>
                            <th>GA/Game</th>
                            <th>GD/Game</th>
                            <th>Win%</th> */}
                            {/* <th>Championships</th> */}
                        </tr>
                    </thead>
                    <tbody className='stats-tbody'>
                        {/* All the data cells - use PlayerComponent */}
                        {playersStatsData.toSorted((a, b) => (a[sortableColumnMap[sortColumn]] - b[sortableColumnMap[sortColumn]]) * (sortAscending ? 1 : -1))
                            .map((playerStats, i) => (
                                <PlayersStats
                                    i={i}
                                    key={playerStats.player_name + playerStats.number}
                                    Number={playerStats.number}
                                    Name={playerStats.player_name}
                                    Position={playerStats.player_position}
                                    GF={playerStats.gf}
                                    GA={playerStats.ga}
                                    GD={playerStats.gd}
                                    W={playerStats.wins}
                                    T={playerStats.draws}
                                    L={playerStats.losses}
                                    Played={playerStats.games}
                                    Points={playerStats.points}
                                    GFperGame={playerStats.gf_game}
                                    GAperGame={playerStats.ga_game}
                                    GDperGame={playerStats.gd_game}
                                    WinPercentage={playerStats.win_percent}
                                    Championships={playerStats.Championships}
                                    restOfStats={playerStats}
                                />
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default StatsPage;

