import React from 'react';
import { useMediaQuery } from 'react-responsive';

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const PlayersStats = ({
  i,
  Number,
  Name,
  Position,
  GF=getRandomInt(1, 100),
  GA=getRandomInt(1, 100),
  GD=getRandomInt(1, 100),
  W=getRandomInt(1, 100),
  T=getRandomInt(1, 100),
  L=getRandomInt(1, 100),
  Played=getRandomInt(1, 100),
  Points=getRandomInt(1, 100),
  GFperGame=getRandomInt(1, 100),
  GAperGame=getRandomInt(1, 100),
  GDperGame=getRandomInt(1, 100),
  WinPercentage=getRandomInt(1, 100), 
  Championships=getRandomInt(1, 100),
}) => {
  
  const isTabletOrMobile = useMediaQuery({ query : '(max-width: 1224px)'})

  return (
    <tr key={Name+Number}>
      <td className={i % 2 === 0 ? 'fix-column-even' : 'fix-column-odd'}>{Name}</td>
      <td className=''>{Position}</td>
      <td>{W || 0}</td>
      <td>{T || 0}</td>
      <td>{L || 0}</td>
      <td>{Played || 0}</td>
      <td>{Points || 0}</td>
      <td>{GF ?? 0}</td>
      <td>{GA ?? 0}</td>
      <td>{GD ?? 0}</td>
      <td>{GFperGame ? GFperGame.toFixed(2) : 0}</td>
      <td>{GAperGame ? GAperGame.toFixed(2) : 0}</td>
      <td>{GDperGame ? GDperGame.toFixed(2) : 0}</td>
      <td>{WinPercentage ? WinPercentage.toFixed(3) : 0}</td>
      {/* <td>{Championships || 0}</td> */}
    </tr>
  );
};

export default PlayersStats;

    