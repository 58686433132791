import React from 'react';
import './App.css';

const StyledCheckbox = ({checked, onChange}) => {

  return (
    <>
    <p style={{color : checked ? 'green' : 'red', fontWeight : 'bold'}}>{checked ? "\u2713" : "\u2717"}</p>
    {/* <input
                  className="form-check-input"
                  type="checkbox"
                  checked={checked}
                  onChange={onChange}
                /> */}

    {/* <div className="form-check">
      <input className="form-check-input" type="checkbox" id="styledCheckbox" checked={checked} onChange={onChange} />
      <label className="form-check-label" htmlFor="styledCheckbox">
      </label>
    </div> */}
    </>
  );
};

export default StyledCheckbox;

