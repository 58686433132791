import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { AuthContext } from './App';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosAuthService } from './hooks/useAxios';

export const SignIn = () => {
    const authContext = useContext(AuthContext);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [incorrectLogin, setIncorrectLogin] = useState(false);

    const login = (currentUsername, currentPassword) => {
        setPassword("");
        // https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
        // creating URL encoded form body
        const details = {
            grant_type: "password",
            username: currentUsername,
            password: currentPassword,
            scope: "",
            client_id: "string",
            client_secret: "string"
        };

        let formBody = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        axiosAuthService.post("/token", formBody)
            .then(resp => {
                if (resp.status !== 200) throw Error();
                return resp.data;
            })
            .then(data => {
                authContext.setUserInfo(data)
                const parsedBody = JSON.parse(atob(data.access_token.split('.')[1]));
                authContext.setPermissions(parsedBody.permissions)
                navigate("/")
            })
            .catch((err) => {
                setIncorrectLogin(true);
                console.log(err);
            });
    }

    return (
        <div style={{ padding: "1rem" }}>
            <Form>
                <Form.Group className="mb-3" controlId="formBasic">
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="text" placeholder="Enter Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </Form.Group>

                <Button variant="primary" onClick={() => login(username, password)}>
                    Submit
                </Button>

                {
                    incorrectLogin &&
                    <Form.Group>
                        <Form.Label style={{ color: "red", paddingTop: "10px" }}>Incorrect username or password.</Form.Label>
                    </Form.Group>
                }
            </Form>
        </div>
    );
}
