import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { IoPerson } from "react-icons/io5";
import { Game } from './Game';
import { AuthContext } from './App';
import useAxios from './hooks/useAxios';


export const GameRound = ({ practiceName, teamNumberIdMappings, roundInfo, roundNumber, locked }) => {
    const authContext = useContext(AuthContext);
    const axios = useAxios();

    const OUT = -1;
    
    const [showTeams, setShowTeams] = useState(false);
    const [playerTeams, setPlayerTeams] = useState(null);

    const handleClose = () => setShowTeams(false);
    const handlleOpen = () => {
        setShowTeams(true);
        axios.get(`/player/team?practice_id=${practiceName}&round_number=${roundNumber}`)
        .then(response => {
            if (response.status !== 200) throw new Error("Response didn't return with status code 200")
            return response.data
        })
        .then(playerTeamsResp => { setPlayerTeams(playerTeamsResp); })
        .catch((e) => {
            console.log(`Getting round failed: ${e}`)
        })
    }

    const updatePlayerTeam = (index, update_player_id, from_team_number, to_team_number) => {
        const update_from_team_id = teamNumberIdMappings[from_team_number];
        const update_to_team_id = teamNumberIdMappings[to_team_number];

        const playerTeamsCopy = [...playerTeams];
        playerTeamsCopy[index].team_number = to_team_number;
        playerTeamsCopy[index].team_id = update_to_team_id;
        setPlayerTeams(playerTeamsCopy);

        axios.patch('/player/team', {
            player_id: update_player_id,
            from_team_id: update_from_team_id,
            to_team_id: update_to_team_id
        })
        .then(resp => {
            if (resp.status !== 200) throw new Error("Response didn't return with status code 200")
            return resp.data
        })
        .catch((error) => {
            console.log(error)
            const playerTeamsCopy = [...playerTeams];
            playerTeamsCopy[index].team_number = from_team_number;
            playerTeamsCopy[index].team_id = update_from_team_id;
            setPlayerTeams(playerTeamsCopy);
        })
    }

    return <>
        <tr>
            <th colSpan={3}>{`Round ${roundNumber}`}</th><th colSpan={3} className='right-text'><IoPerson onClick={handlleOpen} className='person-icon' /></th>
        </tr>
        {roundInfo.map((gameInfo) => (
            <Game key={`${gameInfo.game_id}`} teamNumberIdMappings={teamNumberIdMappings} gameInfo={gameInfo} locked={locked} />
        ))}

        <Modal show={showTeams}>
            <Modal.Header>
                <Modal.Title>Teams for Round {roundNumber}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    playerTeams && <table className="table table-striped table-hover '">
                        <thead>
                            <tr>
                                <th>Player</th>
                                <th>Team</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                playerTeams.map((playerTeam, index) => <tr key={playerTeam.player_name}>
                                    <td>{playerTeam.player_name}</td>
                                    <td className={playerTeam.team_number === OUT ? "player-team-out" : ""}>
                                        {
                                        !authContext.permissions.includes("UPDATE") ? 
                                            (playerTeam.team_number === OUT ? "Out" : playerTeam.team_number) :
                                            <select value={playerTeam.team_number} onChange={(e) => updatePlayerTeam(index, playerTeam.player_id, playerTeam.team_number, parseInt(e.target.value))}>
                                                {Object.keys(teamNumberIdMappings).map((key) => (
                                                    <option key={key} value={key}>
                                                        {key === OUT ? "Out" : key }
                                                    </option>
                                                ))}
                                            </select>
                                        }
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    Done
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}