/*
!!! DISCLAIMER !!!

This code was originally taken from the Course Wright project developed by Luke Harwood.
It has been adapted and commented further for the given use case, but credit
for this code should be given to Luke Harwood (GitHub: lukeharwood11).
*/

import { useContext, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
// import toast from "react-hot-toast";
import { AuthContext } from "../App";
import axios from "axios";

const BASE_GAMES_URL = "https://t4dcy4rr8f.execute-api.us-east-2.amazonaws.com/dev" //"http://localhost:8080"; 
const BASE_AUTH_URL =  "https://6aunsichzl.execute-api.us-east-2.amazonaws.com/dev" //"http://localhost:8081"; "https://6aunsichzl.execute-api.us-east-2.amazonaws.com/dev"
// const FRONTEND_URL = "http://localhost:3000" // https://raider-stats.com

export const axiosGamesService = axios.create({
    baseURL : BASE_GAMES_URL,
    // withCredentials: true,
    // headers : {
    //     "Access-Control-Allow-Origin": FRONTEND_URL
    // },
    "Content-Type" : "application/json"
})

export const axiosAuthService = axios.create({
    baseURL : BASE_AUTH_URL,
    withCredentials: true,
    // headers : {
    //     "Access-Control-Allow-Origin": FRONTEND_URL
    // },
    "Content-Type" : "application/json"
})

export const useRefresh = () => {
    const authContext = useContext(AuthContext);

    return async () => {
        try {
            const response = await axiosAuthService.post("/token/refresh", { withCredentials: true });
            const data = response.data
            authContext.setUserInfo(data)
            const parsedBody = JSON.parse(atob(data.access_token.split('.')[1]));
            authContext.setPermissions(parsedBody.permissions)
            console.log(`Refresh ${parsedBody.permissions}`)
            return data.access_token;
        } catch (err) {
            console.error(console.log(`Couldn't refresh token ${err}`))
        }
    };
};

export const useAxiosAuth = () => {

    const authContext = useContext(AuthContext);
    const navigate = useNavigate()
    // const location = useLocation()
    const refresh = useRefresh()
    
    useEffect(() => {

        // ------------ what to do when accepting responses ------------
        const response = axiosAuthService.interceptors.response.use( (response) => {
            return response;
          }, async (error) => {
            const config = error.config

            // if you get a permissions error, and haven't retried, try to use the refresh endpoint
            if (error?.response?.status === 403 && !config._retry) {
                config._retry = true
                // useRefresh() sets the auth state
                const accessToken = await refresh()
                // config.headers[]
                config.headers['Authorization'] = `Bearer ${accessToken}`
                // destructuring and converting config.headers to json to fix DOM exception
                return axiosAuthService({
                    ...config,
                    headers: config.headers.toJSON()
                })
            } else if (error?.response?.status === 403) {
                // toast.error("Session expired. Please Sign in.", { id: "failure" })
                navigate("/sign-in") // , { replace: true, state: { from: location }}
            }
            return Promise.reject(error);
        });

        // ------------ what to do when sending requests ------------
        const request = axiosAuthService.interceptors.request.use((config) => {
            // if we are retrying we already set the accessToken, so skip
            if (!config._retry && authContext.userInfo.access_token) {
                config.headers['Authorization'] = `Bearer ${authContext.userInfo.access_token}`
            }
            return config;
          }, (error) => Promise.reject(error));

        return () => {
            axiosAuthService.interceptors.response.eject(response) // what to when responses come back
            axiosAuthService.interceptors.request.eject(request) // what to do when requests are sent
        }
    }, [authContext.userInfo, refresh, navigate])

    return axiosAuthService
}

const useAxios = () => {

    const authContext = useContext(AuthContext);
    const navigate = useNavigate()
    // const location = useLocation()
    const refresh = useRefresh()
    
    useEffect(() => {

        // ------------ what to do when accepting responses ------------
        const response = axiosGamesService.interceptors.response.use( (response) => {
            return response;
          }, async (error) => {
            const config = error.config

            // if you get a permissions error, and haven't retried, try to use the refresh endpoint
            if (error?.response?.status === 403 && !config._retry) {
                config._retry = true
                // useRefresh() sets the auth state
                const accessToken = await refresh()
                // config.headers[]
                config.headers['Authorization'] = `Bearer ${accessToken}`
                // destructuring and converting config.headers to json to fix DOM exception
                return axiosGamesService({
                    ...config,
                    headers: config.headers.toJSON()
                })
            } else if (error?.response?.status === 403) {
                // toast.error("Session expired. Please Sign in.", { id: "failure" })
                navigate("/sign-in") // , { replace: true, state: { from: location }}
            }
            return Promise.reject(error);
        });

        // ------------ what to do when sending requests ------------
        const request = axiosGamesService.interceptors.request.use((config) => {
            // if we are retrying we already set the accessToken, so skip
            if (!config._retry && authContext.userInfo.access_token) {
                config.headers['Authorization'] = `Bearer ${authContext.userInfo.access_token}`
            }
            return config;
          }, (error) => Promise.reject(error));

        return () => {
            axiosGamesService.interceptors.response.eject(response) // what to when responses come back
            axiosGamesService.interceptors.request.eject(request) // what to do when requests are sent
        }
    }, [authContext.userInfo, refresh, navigate])

    return axiosGamesService
}

export default useAxios;