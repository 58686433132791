export const coaches =  [{
    name: 'Rob Harrington',
    x: null,
    y: null,
    width: 55, // Adjust width as needed
    height: 20, // Adjust height as needed
    category : 'coaches'
  },
  {
    name: 'John M.',
    x: null,
    y: null,
    width: 55, // Adjust width as needed
    height: 20, // Adjust height as needed
    category : 'coaches'
  },
  {
    name: 'Joey S',
    x: null,
    y: null,
    width: 55, // Adjust width as needed
    height: 20, // Adjust height as needed
    category : 'coaches'
  },
  {
    name: 'Derek M.',
    x: null,
    y: null,
    width: 55, // Adjust width as needed
    height: 20, // Adjust height as needed
    category : 'coaches'
  },
  {
    name: 'Caden P.',
    x: null,
    y: null,
    width: 55, // Adjust width as needed
    height: 20, // Adjust height as needed
    category : 'coaches'
  },
  {
    name: 'Ethan K.',
    x: null,
    y: null,
    width: 55, // Adjust width as needed
    height: 20, // Adjust height as needed
    category : 'coaches'
  },
];
