import React from 'react';

const ProgressCheckpoint = ({checkpoint, progress, onClick}) => {
    return (
        <span
            className={`badge badge-pill ${progress >= checkpoint.id ? "badge-primary" : "badge-secondary"}`}
            key={checkpoint.id}
            onClick={onClick}
            style={{
                padding: '10px',
                borderWidth : '0',
                cursor: 'pointer',
            }}
          >
            {checkpoint.label}
          </span>
    );
};

const ProgressBar = ({checkpoints, progress, setProgress}) => {
  

//   const checkpoints = [
//     { id: 0, label: 'Step 1' },
//     { id: 1, label: 'Step 2' },
//     { id: 2, label: 'Step 3' },
//     // Add more checkpoints as needed
//   ];

//   const handleDotClick = (checkpointId) => {
//     setProgress(checkpointId);
//   };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {checkpoints.map((checkpoint) => (
          <ProgressCheckpoint key={checkpoint.label} checkpoint={checkpoint} progress={progress} onClick={() => setProgress(checkpoint.id)}/>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
