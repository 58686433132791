import React, { useContext, useEffect, useState } from 'react';
// import axios from 'axios';
import PracticePlayer from './PracticePlayer';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/Form';
import { AuthContext } from './App';
import useAxios from './hooks/useAxios';

function GeneratePage({ data, locked }) {
  const authContext = useContext(AuthContext);
  const axios = useAxios();
  const [teams, setTeams] = useState([]);
  const [numTeams, setNumTeams] = useState(4);
  const [practiceName, setPracticeName] = useState("");
  const [nameValid, setNameValid] = useState(false);
  const [numberTeamsValid, setNumberTeamsValid] = useState(true);
  const [teamSheetMessage, setTeamSheetMessage] = useState("Press Generate Teams to View");
  const [practiceList, setPracticesList] = useState(['']);
  const [practiceType, setPracticeType] = useState(''); // Add state for practice type
  const [practiceTypeValid, setPracticeTypeValid] = useState(false); // Add state for practice type validation
  useEffect(() => {
      axios.get('/practice/list')
      .then(resp => {
        if (resp.status !== 200) throw new Error("Response didn't return with status code 200")
        return resp.data // json()
      })
      .then(data => {
        setPracticesList(data);
      })
      .catch((e) => {
        console.log(`Loading practice names failed: ${e}`)
      })
  }, [axios]);

  const handleGenerateClick = async () => {
    try {
      const response = await axios.post(`/teams?num_teams=${numTeams}`, data);
      const response_data = await response.data; // json();
      setTeams(response_data.teams);
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };

  const handleNumTeamsChange = (event) => {
    const inputValue = event.target.value;

    if (/^[0-9]*$/.test(inputValue)) {
      setNumberTeamsValid(true);
      if (/^[0-9]+$/.test(inputValue)) {
        setNumTeams(parseInt(inputValue));
      } else {
        setNumTeams(inputValue);
      }
    }
    if (inputValue < 1 || !/^[0-9]+$/.test(inputValue)) {
      setNumberTeamsValid(false);
    }
  };
  const handlePracticeTypeChange = (e) => {
    setPracticeType(e.target.value); // Update practice type
    setPracticeTypeValid(e.target.value !== ''); // Update practice
  }
  const onSegmentNameChange = (e) => {
    setNameValid(/^[0-9]{4}-[0-9]{2}-[0-9]{2}-.+/.test(e.target.value) && !practiceList.includes(e.target.value));
    setPracticeName(e.target.value);
    // console.log(e.target.value)
    // console.log(practiceList.includes(e.target.value))
    // console.log(/^[0-9]{4}-[0-9]{2}-[0-9]{2}-.+/.test(e.target.value))
    // console.log(numberTeamsValid, nameValid, numTeams, teams.length)
  }

  const handleCreatePracticeClick = () => {
    if (!authContext.permissions.includes("CREATE")) return;
    const newTeams = [];
    teams.forEach(team => {
      newTeams.push({
        team_number: parseInt(team.team_name.split(" ")[1]),
        players: team.players
      })
    })
    // return;
    const dataPractice = {
      practice_name: practiceName,
      teams: newTeams,
      practiceType: practiceType
    }

    axios.post('/practice', dataPractice)
      .then(resp => {
        if (resp.status === 200) {
          console.log("Success!")
          setTeamSheetMessage(`Added practice ${practiceName} to practices page. Press Generate Teams to start making another practice.`)
          setTeams([]);
          const practicesListCopy = [...practiceList];
          practicesListCopy.push(practiceName);
          setPracticesList(practicesListCopy);
        }
      });
  }

  return (
    <div>
      <Form style={{ margin: "1rem" }}>
        <Form.Group className="mb-3" controlId="formSegmentName">
          <Form.Label>{"Practice Segment Name"}</Form.Label>
          <Form.Control type="text" placeholder="YYYY-MM-DD-name" value={practiceName} name="practiceName" onChange={onSegmentNameChange} isInvalid={!nameValid} />
          <Form.Text className="text-muted">
            Practice must be unique & of form <i>YYYY-MM-DD-name</i>.
          </Form.Text>
        </Form.Group>
        <Form.Select value={practiceType} onChange={handlePracticeTypeChange} className="custom-select">
          <option value="" disabled>Select Practice Type</option>
          <option value="Crossing Game">
            <i className="fas fa-dumbbell"></i> Crossing Game
          </option>
          <option value="Finishing Game">
            <i className="fas fa-users"></i> Finishing Game
          </option>
          <option value="4 vs 4">
            <i className="fas fa-futbol"></i> 4 vs 4
          </option>
        </Form.Select>
        <Form.Group className="mb-3" controlId="numTeams">
          <Form.Label>Number of Teams</Form.Label>
          <Form.Control type="number" placeholder="Number of Teams" value={numTeams} name='numTeams' onChange={handleNumTeamsChange} isInvalid={!numberTeamsValid} />
        </Form.Group>

        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Button variant="primary" onClick={handleGenerateClick} disabled={!numberTeamsValid}>
            Generate Teams
          </Button>
          <Button variant="primary" onClick={handleCreatePracticeClick} disabled={ !practiceTypeValid || !nameValid || !numberTeamsValid || numTeams !== teams.length || !authContext.permissions.includes("CREATE")}>
            Create Practice
          </Button>
        </div>
                {/* Add this block for practice type selection */}

      </Form>

      <h1>Team Sheet</h1>
      {
        teams.length === 0 ?
          <p><i>{teamSheetMessage}</i></p> :
          teams.map((team, index) => (
            <div key={index}>
              <h2>{team.team_name}</h2>
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Position</th>
                  </tr>
                </thead>
                <tbody>
                  {team.players.map((player) => (
                    <PracticePlayer
                      key={player.name}
                      name={player.name}
                      position={player.position}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          ))}

    </div>
  );
}

export default GeneratePage;
