import { isPointNearLine } from "./canvasUtils";

export const handleMouseDown = (e, canvas, players, setDragging, deleteMode, 
  setPlayers, setLines, isDrawingLine, setLineStart, canvasRef, lines, 
  isDrawingCurvedLine, isTextBoxMode, setTextBoxes, textBoxes, setDraggingTextBox) => {

  const rect = canvas.getBoundingClientRect();
  const x = e.clientX - rect.left;
  const y = e.clientY - rect.top;

  const player = players.find(
    (p) => x >= p.x && x <= p.x + p.width && y >= p.y && y <= p.y + p.height
  );

  const textBox = textBoxes.find(
    (box) => x >= box.x && x <= box.x + box.width && y >= box.y && y <= box.y + box.height
  );

  if (player) {
    if (deleteMode) {
      setPlayers(prevPlayers =>
        prevPlayers.map(p =>
          p.name === player.name ? { ...p, x: null, y: null } : p
        )
      );
    } else {
      setDragging({ ...player, offsetX: x - player.x, offsetY: y - player.y });
    }
  }

  if (textBox) {
    if (deleteMode) {
      setTextBoxes(prevTextBoxes => prevTextBoxes.filter(tb => tb !== textBox));
    } else {
      setDraggingTextBox({ ...textBox, offsetX: x - textBox.x, offsetY: y - textBox.y });
    }
  }

  if (deleteMode && !player && !textBox) {
    const lineIndex = lines?.findIndex(line => {
      if (line && line.path) {
        return line.path.some((point, idx) => {
          if (idx === 0) return false;
          const prevPoint = line.path[idx - 1];
          return isPointNearLine(x, y, prevPoint.x, prevPoint.y, point.x, point.y);
        });
      } else if (line && line.start && line.end) {
        return isPointNearLine(x, y, line.start.x, line.start.y, line.end.x, line.end.y);
      }
      return false;
    });
    if (lineIndex !== -1) {
      setLines(prevLines => prevLines.filter((_, idx) => idx !== lineIndex));
    }
  }

  if (isDrawingLine) {
    setLineStart({ x, y });
    setLines(prevLines => [...prevLines, { start: { x, y }, end: { x, y } }]);
  }

  if (isDrawingCurvedLine) {
    setLineStart({ x, y });
    setLines(prevLines => [...prevLines, { path: [{ x, y }] }]);
  }

  if (isTextBoxMode) {
    const text = prompt("Enter the text for the box:");
  
    if (text) {
      const newBox = {
        x,
        y,
        text,
        width: 100,
        height: 30
      };
      setTextBoxes(prevBoxes => [...prevBoxes, newBox]);
    }
    return; // Exit early to prevent other actions
  }
};




export const handleMouseMove = (e, canvas, dragging, setPlayers, isDrawingLine, lineStart, canvasRef, setLines, isDrawingCurvedLine, draggingTextBox, setTextBoxes) => {
  if (dragging) {
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left - dragging.offsetX;
    const y = e.clientY - rect.top - dragging.offsetY;

    setPlayers((prevPlayers) =>
      prevPlayers.map((p) =>
        p.name === dragging.name ? { ...p, x, y } : p
      )
    );
  }

  if (draggingTextBox) {
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left - draggingTextBox.offsetX;
    const y = e.clientY - rect.top - draggingTextBox.offsetY;

    setTextBoxes(prevTextBoxes =>
      prevTextBoxes.map(tb =>
        tb.text === draggingTextBox.text ? { ...tb, x, y } : tb
      )
    );
  }

  if (isDrawingLine && lineStart) {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setLines(prevLines => {
      const newLines = [...prevLines];
      newLines[newLines.length - 1].end = { x, y };
      return newLines;
    });
  }

  if (isDrawingCurvedLine && lineStart) {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setLines(prevLines => {
      const newLines = [...prevLines];
      newLines[newLines.length - 1].path.push({ x, y });
      return newLines;
    });
  }
};


export const handleMouseUp = (setDragging, isDrawingLine, canvasRef, setLines, setLineStart, lineStart, e, isDrawingCurvedLine, setDraggingTextBox) => {
  setDragging(null);
  setDraggingTextBox(null);

  if (isDrawingLine && lineStart) {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setLines(prevLines => {
      const newLines = [...prevLines];
      newLines[newLines.length - 1].end = { x, y };
      return newLines;
    });
    setLineStart(null);
  }

  if (isDrawingCurvedLine && lineStart) {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setLines(prevLines => {
      const newLines = [...prevLines];
      newLines[newLines.length - 1].end = { x, y };
      return newLines;
    });
    setLineStart(null);
  }
};


export const handleDragStart = (e, player, setPlayers) => {
  e.dataTransfer.setData('text/plain', player.name);
  setPlayers((prevPlayers) =>
    prevPlayers.map((p) =>
      p.name === player.name ? { ...p, isDragging: true } : p
    )
  );
};

export const handleDrop = (e, canvas, players, setPlayers) => {
  e.preventDefault();
  const playerName = e.dataTransfer.getData('text/plain');
  const rect = canvas.getBoundingClientRect();
  const x = e.clientX - rect.left;
  const y = e.clientY - rect.top;

  // Check if the drop is inside the canvas
  if (
    e.clientX >= rect.left &&
    e.clientX <= rect.right &&
    e.clientY >= rect.top &&
    e.clientY <= rect.bottom
  ) {
    // Drop inside canvas
    setPlayers(prevPlayers =>
      prevPlayers.map(player =>
        player.name === playerName ? { ...player, x, y, isDragging: false } : player
      )
    );
  } else {
    // Drop outside canvas, reset player position
    setPlayers(prevPlayers =>
      prevPlayers.map(player =>
        player.name === playerName ? { ...player, x: null, y: null, isDragging: false } : player
      )
    );
  }
};


export const handleDragOver = (e) => {
  e.preventDefault();
};

export const mapPlayersInfoToState = (playersInfo) => {
  return playersInfo.map(player => {
    let category;
    switch (player.position) {
      case 'F':
        category = 'forwards';
        break;
      case 'MF':
        category = 'midfielders';
        break;
      case 'D':
        category = 'defenders';
        break;
      case 'GK':
        category = 'goalkeepers';
        break;
      default:
        category = 'unknown';
        break;
    }
    return {
      name: player.name,
      x: null,
      y: null,
      width: 55, // Adjust width as needed
      height: 20, // Adjust height as needed
      category
    };
  });
};

