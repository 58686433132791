import React, { useState } from 'react';

function TrackResults() {
    const generateDummyData = () => {
        const teams = [];
        for (let i = 0; i < 4; i++) {
            teams.push({ name: `Team ${i+1}` });
        }
        return teams;
    };

    return (
        <>
            <h2>Enter Scores for Round 1</h2>
            {generateDummyData().map((team, index) => (
                <div key={index}>
                    <label>{team.name} Score</label>
                    <input type="number" />
                </div>
            ))}
            <button>Submit</button>
        </>
    );
}

export default TrackResults;
