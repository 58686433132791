import React from 'react'; // import react

// number, name, position, onCheckBoxChange are the props (like parameters)
const PracticePlayer = ({ name, position }) => {
  return (
    <tr>
      <td>{name}</td>
      <td>{position}</td>
    </tr>
  );
};

export default PracticePlayer;